import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { Container, Row, Col, Button, Spinner, Modal } from "react-bootstrap"
import $ from "jquery"
import Navbar from "../../components/navbar"
import Footer from "../../components/footer"
import { VerifyUserPermission } from "../../functions/verifyUserPermision"
import { getPictList } from "../../services/admin"
import PictModal from "../../components/pictModal"

const isBrowser = typeof window !== "undefined";

function PictManager() {
  const [userVerified, setUserVerified] = useState(false)
  useEffect(() => {
    const verifyUser = async () => {
      const userHasPermission = await VerifyUserPermission("use-img-manager")
      if (!userHasPermission) {
        navigate("/403")
      } else {
        setUserVerified(true)
      }
    }
    if (!userVerified) verifyUser()
  }, [userVerified])

  const [picturesContent, setPicturesContent] = useState("")
  const [buttonSubmitEnabled, setButtonSubmitEnabled] = useState(true)
  const [modalBlogSaved, setModalBlogSaved] = useState(false)
  const [modalError, setModalError] = useState(false)
  const [modalErrorText, setModalErrorText] = useState("")
  const [s3Content, setS3Content] = useState({})
  const [imageToOpen, setImageToOpen] = useState("")
  const [showImage, setShowImage] = useState(false)
  const [imageHeight, setImageHeight] = useState(0)

  useEffect(() => {
    if (isBrowser) {
      setImageHeight($(window).height() - 80)
      if (typeof window !== "undefined") {
        $(window).on("load", function () {
          setImageHeight($(window).height() - 80)
        })
      }
    }

    const efJwt = localStorage.getItem("jwt")

    async function fetchData(jwt) {
      await getPictList(jwt, { permission: "use-img-manager" }).then(res => {
        if (res.status === 201) {
          setS3Content(res.data.Contents)
        } else {
          if (res.status === 400) {
            setModalErrorText("erro")
            setButtonSubmitEnabled(true)
            setModalError(true)
          } else {
            setModalErrorText("erro " + res.status + " " + res.message)
            setButtonSubmitEnabled(true)
            setModalError(true)
          }
        }
      })
    }

    fetchData(efJwt)
  }, [])

  useEffect(() => {
    function createPicturesContet() {
      return s3Content.map((s3picture, k) => {
        const pictName = s3picture.Key.split("/")[1]
        return s3picture.Key !== process.env.GATSBY_SITE_PICTURES_FOLER ? (
          <div key={k}>
            <div className="pict-button-wrapper">
              <Button onClick={e => handleOpenPict(e)} className="pict-button">
                <div className="pict-wrapper">
                  <img
                    src={
                      "https://" +
                      process.env.GATSBY_SITE_PICTURES_BUCKET +
                      "/" +
                      s3picture.Key
                    }
                    alt={s3picture.Key}
                  />
                </div>
              </Button>
              <Button size="sm" className="pict-select-button">
                selecionar
              </Button>
            </div>
            <div className="pict-text">
              <p>{pictName}</p>
            </div>
          </div>
        ) : null
      })
    }
    if (Object.entries(s3Content).length !== 0)
      setPicturesContent(createPicturesContet())
  }, [s3Content])

  const handleOpenPict = e => {
    setImageToOpen(e.target.src)
    setShowImage(true)
  }

  const hidePict = e => {
    setShowImage(false)
  }

  const removeModal = () => {
    setModalBlogSaved(false)
  }

  const removeModalError = () => {
    setModalError(false)
  }

  return (
    <>
      <PictModal
        show={showImage}
        handleOpen={handleOpenPict}
        handleClose={hidePict}
        image={imageToOpen}
        imageHgt={imageHeight}
      />
      <Modal show={modalBlogSaved}>
        <Modal.Body>
          <p>O conteúdo de seu blog foi salvo.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={removeModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={modalError}>
        <Modal.Body>
          <p>{modalErrorText}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={removeModalError}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
      <Navbar />
      <div className="height-40" />
      <Container>
        <Row>
          <Col>
            <h1>Picture Manager</h1>
          </Col>
        </Row>
      </Container>
      {userVerified ? (
        <Container>
          <Row>{picturesContent}</Row>
          <Row>
            <Col>
              <div style={{ height: "20px" }} />
              <Button>Upload</Button>
              <div
                style={{
                  display: !buttonSubmitEnabled ? "block" : "none",
                  float: "left",
                  margin: "4px 0 0 6px",
                }}
              >
                <Spinner animation="border" variant="secondary" />
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container>
          <Row>
            <Col>
              <Spinner animation="border" size="sm" variant="primary" />
            </Col>
          </Row>
        </Container>
      )}

      <div className="height-40" />
      <Footer />
    </>
  )
}

export default PictManager
