import { clearConsole } from "../functions/clearConsole"
const axios = require("axios")

export const getRoles = async (jwt, data) => {
  const params = {
    method: "post",
    url: process.env.GATSBY_API_URL + "/admin/get-roles",
    headers: {
      Authorization: "Bearer " + jwt,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  }
  try {
    return await axios(params)
  } catch (error) {
    if (error.response.status === 401) {
      return {
        res: {
          isAxiosError: true,
        },
        status: error.response.status,
      }
    } else {
      clearConsole()
      console.log("getRoles error", error)
      return error
    }
  }
}
export const getUserPermission = async (jwt, data) => {
  try {
    return await axios({
      method: "post",
      url: process.env.GATSBY_API_URL + "/admin/get-user-permission",
      headers: {
        Authorization: "Bearer " + jwt,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    })
  } catch (error) {
    if (error.response.status === 401) {
      return {
        data: {
          isAxiosError: true,
          userHasPermission: false,
        },
        status: error.response.status,
      }
    } else {
      if (error.response.status === 401) {
        return { res: { isAxiosError: true } }
      } else {
        console.log("getUserPermission", error)
        clearConsole()
        return error
      }
    }
  }
}

export const createBlog = async (jwt, data) => {
  data["type"] = "blog"
  try {
    return await axios({
      method: "post",
      url: process.env.GATSBY_API_URL + "/admin/create-content",
      headers: {
        Authorization: "Bearer " + jwt,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    })
  } catch (error) {
    if (error.response && error.response.status === 401) {
      return {
        res: {
          isAxiosError: true,
        },
        status: error.response.status,
      }
    } else {
      clearConsole()
      console.log("createBlog", error)
      return error
    }
  }
}

export const createTutorial = async (jwt, data) => {
  data["type"] = "tutorial"
  try {
    return await axios({
      method: "post",
      url: process.env.GATSBY_API_URL + "/admin/create-content",
      headers: {
        Authorization: "Bearer " + jwt,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    })
  } catch (error) {
    if (error.response && error.response.status === 401) {
      return {
        res: {
          isAxiosError: true,
        },
        status: error.response.status,
      }
    } else {
      clearConsole()
      console.log("createTutorials", error)
      return error
    }
  }
}

export const getPictList = async (jwt, data) => {
  const params = {
    method: "post",
    url: process.env.GATSBY_API_URL + "/admin/get-picture-list",
    headers: {
      Authorization: "Bearer " + jwt,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  }
  try {
    return await axios(params)
  } catch (error) {
    if (error.response.status === 401) {
      return {
        res: {
          isAxiosError: true,
        },
        status: error.response.status,
      }
    } else {
      clearConsole()
      console.log("getPictList", error)
      return error
    }
  }
}

export const uploadFile = async (jwt, data, file, onUploadProgress) => {
  let formData = new FormData()
  formData.append("file", file)
  formData.append("permission", data.permission)
  try {
    const params = {
      method: "post",
      url: process.env.GATSBY_API_URL + "/admin/get-upload-data",
      headers: {
        Authorization: "Bearer " + jwt,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
      onUploadProgress
    }
    const uploadUrl = await axios(params)
    console.log(uploadUrl)
    console.log(file)
    return await axios({
      method: "PUT",
      url: uploadUrl.data.url,
      data: file,
      headers: {
        "Content-Type": file.type,
      },
    });
      
  } catch (error) {
    console.log(error)
    if (error.response.status === 401) {
      return {
        res: {
          isAxiosError: true,
        },
        status: error.response.status,
      }
    } else {
      clearConsole()
      console.log("uploadFile", error)
      return error
    }
  }
}

export const uploadFileOld = async (jwt, data, file, onUploadProgress) => {
  let formData = new FormData()
  formData.append("file", file)
  formData.append("permission", data.permission)
  try {
    const params = {
      method: "post",
      url: process.env.GATSBY_API_URL + "/admin/upload-file",
      headers: {
        Authorization: "Bearer " + jwt,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
      onUploadProgress,
    }
    return await axios(params)
  } catch (error) {
    if (error.response.status === 401) {
      return {
        res: {
          isAxiosError: true,
        },
        status: error.response.status,
      }
    } else {
      clearConsole()
      console.log("uploadFile", error)
      return error
    }
  }
}

export const getBlogsPage = async (jwt, data) => {
  const params = {
    method: "post",
    url: process.env.GATSBY_API_URL + "/admin/get-blog-page",
    headers: {
      Authorization: "Bearer " + jwt,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  }
  try {
    return await axios(params)
  } catch (error) {
    if (error.response.status === 401) {
      return {
        res: {
          isAxiosError: true,
        },
        status: error.response.status,
      }
    } else {
      clearConsole()
      console.log("getBlogsPage", error)
      return error
    }
  }
}

export const getTutorialsPage = async (jwt, data) => {
  const params = {
    method: "post",
    url: process.env.GATSBY_API_URL + "/admin/get-tutorials-page",
    headers: {
      Authorization: "Bearer " + jwt,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  }
  try {
    return await axios(params)
  } catch (error) {
    if (error.response.status === 401) {
      return {
        res: {
          isAxiosError: true,
        },
        status: error.response.status,
      }
    } else {
      clearConsole()
      console.log("getTutorialsPage", error)
      return error
    }
  }
}

export const deleteBlogsEntries = async (jwt, data) => {
  const params = {
    method: "post",
    url: process.env.GATSBY_API_URL + "/admin/delete-blogs-entries",
    headers: {
      Authorization: "Bearer " + jwt,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  }
  try {
    return await axios(params)
  } catch (error) {
    if (error.response.status === 401) {
      return {
        res: {
          isAxiosError: true,
        },
        status: error.response.status,
      }
    } else {
      clearConsole()
      console.log("deleteBlogsEntries", error)
      return error
    }
  }
}

export const deleteTutorialsEntries = async (jwt, data) => {
  const params = {
    method: "post",
    url: process.env.GATSBY_API_URL + "/admin/delete-tutorials-entries",
    headers: {
      Authorization: "Bearer " + jwt,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  }
  try {
    return await axios(params)
  } catch (error) {
    if (error.response.status === 401) {
      return {
        res: {
          isAxiosError: true,
        },
        status: error.response.status,
      }
    } else {
      clearConsole()
      console.log("deleteTutorialsEntries", error)
      return error
    }
  }
}

export const publish = async (jwt, data) => {
  const params = {
    method: "post",
    url: process.env.GATSBY_API_URL + "/admin/publish",
    headers: {
      Authorization: "Bearer " + jwt,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  }
  try {
    return await axios(params)
  } catch (error) {
    if (error.response.status === 401) {
      return {
        res: {
          isAxiosError: true,
        },
        status: error.response.status,
      }
    } else {
      clearConsole()
      console.log("deleteTutorialsEntries", error)
      return error
    }
  }
}
