import { getUserPermission } from "../services/admin"

export const VerifyUserPermission = async permission => {
  if (typeof localStorage !== 'undefined'){
  const jwt = localStorage.getItem("jwt")
  if (jwt === null) {
    return false
  } else {
    const userPermission = await getUserPermission(jwt, {
      permission: permission,
    })
    if (!userPermission.data.userHasPermission){
      // localStorage.clear()
    }
    return userPermission.data.userHasPermission
  }
} else {
  return false
}
}